import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoader from "./components/common/LazyLoader";
import { getRoutes } from "./config/routes";
import { authSelector, setUserInfo } from "./redux/slicers/authSlice";
import { getLocal, getUserDetails } from "./utils/projectHelper";
import { USER_TYPE } from "./config/constants/projectConstant";
import ActivityContainer from "./container/Activity";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { GetUserPermissionApi, updateUserLoggedInStatusApi } from "./api/authApi";
// import { io } from "socket.io-client";

function App() {
  const [loading, setLoading] = useState(false);
  const { userInfo = {} } = useSelector(authSelector);
  const userData = getUserDetails();
  const dispatch = useDispatch();
  const token = getLocal();

  useEffect(() => {
    dispatch(setUserInfo(getUserDetails()));
    if (token && userData?.userType !== 3) {
      setLoading(true);
      dispatch(GetUserPermissionApi(setLoading));
    }
  }, []);


  // const socket = io("http://localhost:5004", {
  //   transports: ['websocket']
  // });

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('connect', (item) => {
  //       console.log('A user connected', item?.id);
  //     });
  //     const userDetails = {
  //       ipAddress: "103.179.223.201",
  //       userId: "65a774c787e5fa9199199bb8",
  //       isDeviceLogin: true,
  //       device: "desktop",
  //       latitude: 28.6130176,
  //       longitude: 77.398016,
  //       deviceId: "6784f94807155d21a8aedecd",
  //       browser: "Chrome"
  //     };


  //     socket.emit('updateDeviceInfo', userDetails)

  //     return () => {
  //       socket.off('connect');
  //     };
  //   }

  // }, [socket]);



  const theme = createTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const userRole = userInfo?.userRole;
  const routeType = userData.userType ?? 1;
  const router = useRoutes(getRoutes(routeType, userRole));

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ActivityContainer>
          {loading ? <LazyLoader /> : <LazyLoader>{router}</LazyLoader>}
          <ToastContainer />
        </ActivityContainer>
      </ThemeProvider>
    </>
  );
}

export default App;

import React, { memo, useEffect, useRef, useState } from "react";
import MulltipleDonutChart from "../../../../../components/common/MultipleDonutChart";
import { useDispatch } from "react-redux";
import { getMultipleCurveDataApi } from "../../../../../api/superAdminApi/dashboard";
import { useInView } from "react-intersection-observer";
import RingLoaderCompoenent from "../../../../../components/common/RingLoader";
import MultipleDonutSkeleton from "./skeleton/multipleDonutSkeleton";

const MultipleDonut = ({
  title,
  dashboardType,
  componentSpecificData,
  componentList,
  clientId = "",
}) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [componentSpecificUrl, setComponentSpecificUrl] = useState("");
  const [multipleCurveData, setMultipleCurveData] = useState({});
  const [totalBatches, setTotalBatches] = useState(0);
  const [completeBatches, setCompleteBatches] = useState(0);
  const [pendingBatches, setPendingBatches] = useState(0);
  const { ref, inView } = useInView({ threshold: 0.5 });
  const prevTextRef = useRef(clientId)



  useEffect(() => {
    if (Object.keys(multipleCurveData)?.length > 0) {
      setTotalBatches(multipleCurveData?.totalBatchCount);
      setCompleteBatches(multipleCurveData?.completedBatchCount);
      setPendingBatches(multipleCurveData?.pendingBatchCount);
    }
  }, [multipleCurveData]);

  const updateData = (data) => {
    if (data) {
      setMultipleCurveData(data);
    }
  };

  const getMultipleCurveData = (url) => {
    dispatch(getMultipleCurveDataApi(url, clientId, setLoading, updateData));
  };

  useEffect(() => {
    if (componentList?.length > 0) {
      const element = componentList?.find((item) => {
        return (
          `${item?.componentId?.component_name} ${item?.componentId?.component_category}` ===
          `${title} ${dashboardType}`
        );
      });
      if (element) {
        setComponentSpecificUrl(element?.componentId?.endpoint);
      }
    }
  }, [componentList]);

  useEffect(() => {
    if (componentSpecificUrl && inView && (clientId !== prevTextRef.current)) {
      prevTextRef.current = clientId;
      setLoading(true);
      getMultipleCurveData(componentSpecificUrl);
    }
    else if(componentSpecificUrl && inView && (clientId === "" && !Object.keys(multipleCurveData)?.length>0)){
      setLoading(true);
      getMultipleCurveData(componentSpecificUrl);
    }
  }, [componentSpecificUrl, inView, clientId]);



  const data = [
    {
      labels: ["Total Batches"],
      datasets: [
        {
          label: "Total Batches",
          data: [totalBatches, 0],
          backgroundColor: ["#8b5cf6", "#e4e4e7"],
        },
      ],
    },
    {
      labels: ["Completed Batches"],
      datasets: [
        {
          label: "Completed Batches",
          data: [completeBatches, pendingBatches],
          backgroundColor: ["#10b981", "#e4e4e7"],
        },
      ],
    },
    {
      labels: ["Pending Batches"],
      datasets: [
        {
          label: "Pending Batches",
          data: [pendingBatches, completeBatches],
          backgroundColor: ["#f59e0b", "#e4e4e7"],
        },
      ],
    },
  ];

  return (
    <div ref={ref}>
      {loading ? (
        // <RingLoaderCompoenent />
        <MultipleDonutSkeleton/>
      ) : (
        <div>
          <h2 style={{ fontSize: "large", fontWeight: "bold" }}>
            Batch Statistics
          </h2>
          <h3 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {totalBatches}
          </h3>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {data.map((chartData, index) => (
              <MulltipleDonutChart
                key={index}
                data={chartData}
                title={
                  index === 0
                    ? "Total Batches"
                    : index === 1
                    ? "Completed Batches"
                    : "Pending Batches"
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(MultipleDonut);

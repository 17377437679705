import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon-grey.svg";
import { useDispatch } from "react-redux";
function SearchInput({
  searchQuery,
  handleTrimPaste,
  setSearchQuery,
  apiHandler,
  setLoading,
  page,
  limit,
  setTotalPages,
}) {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleSearchSubmit = (e) => {
    if (searchQuery !== null) {
      setLoading(true);
      dispatch(apiHandler(setLoading, page, searchQuery, limit, setTotalPages));
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      // handleSearchSubmit();
    }, 500);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search"
        value={searchQuery}
        style={{ background: "#F8F8F8", padding: "2px" }}
        onChange={handleChange}
        onPaste={(e) => handleTrimPaste(e, setSearchQuery)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#231F20", width: 15 }} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default SearchInput;

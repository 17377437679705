import {
  dashboardConstants,
  DEFAULT_TOKEN,
  USER_DATA,
  USER_TYPE,
} from "../config/constants/projectConstant.js";
import { SIGNIN } from "../config/constants/routePathConstants/auth";
import { toast } from "react-toastify";
import { navigatePath, sessionFail } from "../redux/slicers/activitySlice";
import store from "../redux/store";
import validateField from "./validateField.js";
import Swal from "sweetalert2";
import { setUserInfo } from "../redux/slicers/authSlice.js";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import crossSvg from "../assets/images/pages/ReportAnalytics/failcross.png";
import testPass from "../assets/images/pages/ReportAnalytics/pass-check-icon.png";
import moment from "moment";
import {
  STUDENT_BASIC_INFORMATION,
  STUDENT_DETAILS_PAGE,
  STUDENT_FACE_CAPTURE,
  STUDENT_GENERAL_INSTRUCTIONS,
  STUDENT_ID_CAPTURE,
} from "../config/constants/routePathConstants/student.js";
import { Tooltip } from "@mui/material";
//useCommas

export const numberWithCommasString = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithoutCommasString = (x) => {
  const beforeDecimalStr = x.toString()?.split(".");

  if (beforeDecimalStr[1] !== undefined) {
    return (
      beforeDecimalStr[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      "." +
      beforeDecimalStr[1]
    );
  } else {
    return beforeDecimalStr[0]
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const numberWithCommasTrunc = (x) => {
  const trunc = x?.toFixed(2);
  return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommasMath = (x) => {
  const trunc = Math?.trunc(x);
  return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isDev = () => {
  return process.env.NODE_ENV === "development";
};

export const devConsoleLog = (...a) => {
  if (a) {
    if (isDev()) {
    }
  }
};

export const storeLocal = (data = "", tokenName = DEFAULT_TOKEN) => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }
  window.localStorage.setItem(tokenName, data);
};
export const storeSession = (data = "", tokenName = "userData") => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }
  window.sessionStorage.setItem(tokenName, data);
};
export const getLocal = (tokenName = DEFAULT_TOKEN) => {
  const localData = window.localStorage.getItem(tokenName);
  let res;
  try {
    res = JSON.parse(localData);
  } catch (err) {
    res = localData;
  }
  return res;
};
export const getSession = (tokenName = DEFAULT_TOKEN) => {
  const localData = window.localStorage.getItem(tokenName);
  let res;
  try {
    res = JSON.parse(localData);
  } catch (err) {
    res = localData;
  }
  return res;
};
export const getUserData = (userData = USER_DATA) => {
  const localData = window.localStorage.getItem(userData);
  let res = {};
  try {
    res = JSON.parse(localData) || {};
  } catch (err) {
    res = localData || {};
  }
  return res;
};
export const getUserDetails = (userData = USER_DATA) => {
  const localData = window.localStorage.getItem(userData);
  let res = {};
  try {
    res = JSON.parse(localData) || {};
  } catch (err) {
    res = localData || {};
  }
  return res;
};
export const removeLocal = (tokenName = DEFAULT_TOKEN) => {
  window.localStorage.removeItem(tokenName);
  return navigate(SIGNIN);
};

export const sessionDestroy = (path = SIGNIN) => {
  removeLocal();
  removeLocal(USER_DATA);
  dispatcher(sessionFail());
  navigate(path);
  dispatcher(setUserInfo({}));
};

export const uploadPathBuilder = (root, a) => {
  return root + a;
};

export const navigate = (path) => {
  dispatcher(navigatePath(path));
};

export const dispatcher = (a) => {
  store.dispatch(a);
};

export function toolTip(title, maxWidth = "100px") {
  return (
    <Tooltip title={title} arrow>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
          maxWidth: maxWidth, // Set a maximum width for the cell
        }}
      >
        {title || "-"}
      </div>
    </Tooltip>
  );
}

export const successToast = (a) =>
  toast.success(a, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export const errorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const warningToast = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
export const infoToast = () => {
  toast.info("Network Error!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const errorValidator = (a, setState) => {
  const { name, value = "", password } = a;
  if (value.trim()) {
    const { error = null } = validateField(name, value, password);
    setState((err) => {
      return { ...err, [name]: error };
    });
  } else {
    setState((err) => {
      return { ...err, [name]: null };
    });
  }
};
export const errorValidatorPassword = (a, setState) => {
  const { name, value = "" } = a;
  if (value.trim()) {
    const { error = null } = validateField(name, value);
    setState((err) => {
      return { ...err, [name]: error };
    });
  } else {
    setState((err) => {
      return { ...err, [name]: null };
    });
  }
};

export const errorAlert = (msg) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: msg,
  });
};

export const SuccessAlert = (msg) => {
  Swal.fire({
    icon: "success",
    title: msg,
    showConfirmButton: false,
    timer: 5000,
  });
};
export const ConfirmAlert = (msg, navigate, path) => {
  Swal.fire({
    title: msg,
    icon: "success",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "GO BACK TO LOGIN",
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(path);
    }
  });
};

export const capitalizeFunc = (value) => {
  if (!value) {
    return null;
  }

  const arr = value?.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const capitalizeStr = arr.join(" ");

  return capitalizeStr;
};

export const getUserType = (userType) => {
  return USER_TYPE?.find((type) => type.id === userType).label;
};

export const getTimeDifference = (date) => {
  const currentDate = new Date(); // current date/time
  const pastDate = new Date(date); // the past date/time you want to compare

  // calculate the time difference in milliseconds
  const timeDiff = currentDate.getTime() - pastDate.getTime();

  // convert the time difference from milliseconds to seconds, minutes, hours, and days
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return "a few seconds ago";
  }
};

export const blockInvalidChar = (e) => {
  var event = window.event ? window.event : e;
  ["e", "E", "+", "-", "."].includes(e.key) && event.preventDefault();
  if (event.keyCode === 40 || event.keyCode === 38) {
    event.preventDefault();
  }
};

export const exportData = (sortedData, columns) => {
  const result =
    sortedData &&
    sortedData?.map((item, ind) => {
      const newObj = {};
      Object.keys(item)?.map((val) =>
        columns?.map((col) => {
          if (col?.name === "_id") {
            newObj[col?.label] = ind + 1;
          }
          if (col?.name === val) {
            newObj[col?.label] = item[val];
          } else if (val === "clientName") {
            newObj["Client Name"] = item[val];
          }
        })
      );
      return newObj;
    });
  return result;
};

export const exportDataAssessormanagement = (sortedData, columns) => {
  const result =
    sortedData &&
    sortedData?.map((item, ind) => {
      const newObj = {};
      Object.keys(item)?.map((val) =>
        columns?.map((col) => {
          if (col?.name === val) {
            newObj[col?.label] = item[val];
          } else if (val === "clientName") {
            newObj["Client Name"] = item[val];
          }
        })
      );
      return newObj;
    });
  return result;
};

export const exportCandidateListData = (sortedData, columns) => {
  const result = sortedData?.map((item, ind) => {
    const newObj = {};
    columns?.forEach((col) => {
      if (col?.name === "_id") {
        newObj[col?.label] = ind + 1;
      } else if (item.hasOwnProperty(col?.name)) {
        newObj[col?.label] = item[col?.name];
      }
    });
    return newObj;
  });
  return result;
};

export const getResultColor = (result) => {
  switch (result) {
    case "Pass":
      return "rgba(202, 251, 197, 0.5)";
    case "Fail":
      return "rgba(255, 69, 69, 0.05)";
    case "Not-attempt":
      return "rgba(197, 197, 197, 0.5)";
    default:
      return "white";
  }
};
export const getResultTextColor = (resultText) => {
  switch (resultText) {
    case "Pass":
      return "#04D375";
    case "Fail":
      return "#FF0000";
    case "Not-attempt":
      return "#323232";
    default:
      return "blue";
  }
};
export const getResultPreviewTextColor = (resultText) => {
  switch (resultText) {
    case "Pass":
      return "#04D375";
    case "Fail":
      return "#FF0000";
    case "Not-attempt":
      return "#9C9C9C";
    default:
      return "blue";
  }
};
export const getCandidateResultStatus = (resultStatus) => {
  switch (resultStatus) {
    case "Pass":
      return "#04D375";
    case "Fail":
      return "#FF0000";
    case "Not-attempt":
      return "#9C9C9C";
    default:
      return "blue";
  }
};
export const getCandidateResultStatusCircular = (resultStatusCircular) => {
  switch (resultStatusCircular) {
    case "Pass":
      return "#04D375";
    case "Fail":
      return "#F52121";
    case "Not-attempt":
      return "#F1F5F8";
    default:
      return "blue";
  }
};
export const getCandidateResultStatusIcon = (resultStatusIcon) => {
  switch (resultStatusIcon) {
    case "Pass":
      return (
        <CheckBoxOutlinedIcon
          sx={{ fontSize: 20, mr: 2, borderRadius: "5px" }}
        />
      );
    case "Fail":
      return (
        <DisabledByDefaultOutlinedIcon
          sx={{ fontSize: 20, mr: 2, borderRadius: "5px" }}
        />
      );
    case "Not-attempt":
      return (
        <DisabledByDefaultOutlinedIcon
          sx={{ fontSize: 20, mr: 2, borderRadius: "5px" }}
        />
      );
    default:
      return "blue";
  }
};
export const getCandidateResultStatusPdfIcon = (resultStatusPdfIcon) => {
  switch (resultStatusPdfIcon) {
    case "Pass":
      return (
        <img
          width={"20px"}
          src={testPass}
          style={{ marginTop: "4px" }}
          alt="userIcon"
        />
      );
    case "Fail":
      return (
        <img
          width={"20px"}
          src={crossSvg}
          style={{ marginTop: "4px" }}
          alt="userIcon"
        />
      );
    case "Not-attempt":
      return (
        <img
          width={"20px"}
          src={crossSvg}
          style={{ marginTop: "4px" }}
          alt="userIcon"
        />
      );
    default:
      return "blue";
  }
};
export const getCandidateResultStatusPdf = (resultStatus) => {
  switch (resultStatus) {
    case "Pass":
      return "Congratulations !";
    case "Fail":
      return "Unfortunately your score was too low to pass the test.";
    case "Not-attempt":
      return "You have not attempt Exam.";
    default:
      return "blue";
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getUniqueValue = (arr, key) => {
  const uniqueValue = new Set(arr.map((item) => item[key]));
  const resultArr = [...uniqueValue]
    .filter((val) => val !== "" && val !== undefined)
    ?.map((item) => {
      return { label: item, value: item };
    });
  return resultArr;
};

export const convertOptions = (options, value, label, label2) => {
  return options?.map((item) => ({
    label: label
      ? label2
        ? `${item[label]} ${item[label2]}`
        : item[label]
      : item[value],
    value: item[value],
  }));
};

export const userRoleType = (userRole, featureName) => {
  return userRole?.find((item) => item.featureName === featureName);
};
export const getSubRole = (userRole, subFeatureName) => {
  return userRole?.find((item) => item.subFeatureName === subFeatureName);
};

export function padZero(number) {
  return (number < 10 ? "0" : "") + number;
}

export const handleCopyInput = async (value) => {
  try {
    await navigator.clipboard.writeText(value);
  } catch (error) {
    console.error("Copy failed:", error);
  }
};

export const getConvertedMinutesToSeconds = (minutes) => {
  return minutes * 60;
};

export const retriveMatchedWithPath = (path, regex) => {
  const activeTab = path.match(regex);
  return activeTab.join("");
};

export const handleTrimPaste = (event, setter) => {
  // Prevent the default paste behavior
  event.preventDefault();
  // Get the pasted text from the clipboard
  const pastedText = event.clipboardData.getData("text/plain");
  // Remove leading and trailing white spaces and set the state
  setter(pastedText.trim());
};

export function convertPercentageStringToNumber(str) {
  const number = parseInt(str?.replace("%", ""));
  if (!isNaN(number)) {
    return number;
  } else {
    console.error("Invalid percentage string");
    return null;
  }
}

export function checkTimeFormat(timeString) {
  // Check for AM/PM indicator for 12-hour format
  const regex = /AM|PM/i; // The 'i' makes it case-insensitive
  const isValidTime = regex.test(timeString);
  if (!isValidTime) {
    console.log("Matched");
    const newTime = moment(timeString, "HH:mm").format("hh:mmA");
    return newTime;
  }
  return timeString;
}

export const convertDateFormat = (dateString) => {
  // Parse the input date string
  const inputDate = new Date(dateString);

  // Define the days of the week and months arrays
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day, month, and year components
  const dayOfWeek = daysOfWeek[inputDate.getUTCDay()];
  const month = months[inputDate.getUTCMonth()];
  const day = inputDate.getUTCDate();
  const year = inputDate.getUTCFullYear();

  // Create the formatted date string
  const formattedDate = `${month} ${day}, ${dayOfWeek}`;

  return formattedDate;
};
export const convertDateToDDYYMM = (dateString) => {
  // Parse the input date string
  const date = new Date(dateString);

  if (isNaN(date)) {
    return "NA";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const ExamConductLanguages = [
  { label: "Telugu", value: "Telugu" },
  { label: "Assamese", value: "Assamese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Konkani", value: "Konkani" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Kannada", value: "Kannada" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Hindi", value: "Hindi" },
  { label: "Marathi", value: "Marathi" },
  { label: "Meiteilon (Manipuri)", value: "Meiteilon (Manipuri)" },
  { label: "Mizo", value: "Mizo" },
  { label: "Odia", value: "Odia" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu & Urdu", value: "Telugu & Urdu" },
  { label: "Kokborok", value: "Kokborok" },
  { label: "Bengali", value: "Bengali" },
  { label: "Kashmiri", value: "Kashmiri" },
  { label: "Dogri", value: "Dogri" },
  { label: "Urdu", value: "Urdu" },
  { label: "Ladakhi", value: "Ladakhi" },
  { label: "Purgi", value: "Purgi" },
  { label: "Sanskrit", value: "Sanskrit" },
];

export const getDynamicRoute = (data, batchId, candidateId, questionId) => {
  switch (Number(data?.screen)) {
    case 1:
      return {
        step: 1,
        route: navigate(`${STUDENT_DETAILS_PAGE}/${batchId}/${candidateId}`),
      };
    case 2:
      return {
        step: 2,
        route: navigate(
          `${STUDENT_FACE_CAPTURE}/${batchId}/${candidateId}/${questionId}`
        ),
      };
    case 3:
      return {
        step: 3,
        route: navigate(
          `${STUDENT_ID_CAPTURE}/${batchId}/${candidateId}/${questionId}`
        ),
      };
    case 4:
      return {
        step: 4,
        route: navigate(
          `${STUDENT_BASIC_INFORMATION}/${batchId}/${candidateId}/${questionId}`
        ),
      };
    case 5:
      return {
        step: 5,
        route: navigate(
          `${STUDENT_GENERAL_INSTRUCTIONS}/${batchId}/${candidateId}/${questionId}`
        ),
      };
    default:
      return { step: "default", route: "some_navigation_route" };
  }
};

// sort alphabetic order

export const ALPHABETIC_SORT = (data = []) => {
  const sortedData = [...data].sort((a, b) => a.label.localeCompare(b.label));
  return sortedData;
};

export const CLIENT_ALPHABETIC_SORT = (data = []) => {
  const sortedData = [...data].sort((a, b) =>
    a.clientname.localeCompare(b.clientname)
  );
  return sortedData;
};

// cookieHandler.js
export const setCookie = (name, value, days) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const cookieValue = `${name}=${value};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookieValue;
};

export const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
};

export function formatDateInStringFormat(isoString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(isoString);
  return date.toLocaleString("en-US", options);
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

export const stayBackFilter = (label) => {
  if (label !== "QB Management") {
    localStorage.removeItem("qbFilter");
    localStorage.removeItem("qbSearch");
  }
};

export const ButtonList_IndiaMap = (value) => {
  if (value === dashboardConstants?.ClientByLocationBD) {
    return [
      { name: "all", value: "all", label: "All" },
      { name: "private", value: "Private", label: "Private" },
      { name: "government", value: "Government", label: "Government" },
      { name: "others", value: "Others", label: "Others" },
    ];
  } else if (
    value === dashboardConstants?.AssessorByLocationOD ||
    value === dashboardConstants?.AssessorByLocationHR
  ) {
    return [
      { name: "all", value: "all", label: "All" },
      { name: "payroll", value: "payroll", label: "Full-Time" },
      { name: "freelance", value: "freelance", label: "Freelance" },
    ];
  }
};

export const ColorCodes_IndianStates = {
  payroll: [
    "#083344",
    "#155E75",
    "#0E7490",
    "#0891B2",
    "#06B6D4",
    "#22D3EE",
    "#67E8F9",
    "#A5F3FC",
  ],
  freelance: [
    "#713F12",
    "#A16207",
    "#CA8A04",
    "#EAB308",
    "#FACC15",
    "#FDE047",
    "#FEF08A",
    "#FEF9C3",
  ],
  Private: [
    "#054701",
    "#086303",
    "#0a8004",
    "#129c0b",
    "#16ba0d",
    "#1dd613",
    "#32ed28",
    "#57fc4e",
  ],
  Government: [
    "#350438",
    "#410145",
    "#66076b",
    "#85048c",
    "#a50cad",
    "#b806c2",
    "#d71fe0",
    "#f039fa",
  ],
  Others: [
    "#02565c",
    "#015557",
    "#046466",
    "#048285",
    "#029b9e",
    "#08c6c9",
    "#09e4e8",
    "#0ff6fa",
  ],
  total: [
    "#099186",
    "#0bb0a2",
    "#1dbfb2",
    "#15d6c6",
    "#17ebd9",
    "#33f2e2",
    "#5bf5e8",
    "#c2f2ee",
  ],
};

export function currentClockTime(inputDate) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("/");
  const month = parseInt(dateParts[0], 10); // Month is in MM format
  const day = parseInt(dateParts[1], 10); // Day is in DD format
  const year = parseInt(dateParts[2], 10); // Year is in YYYY format

  // Create a new Date object
  const inputDateObj = new Date(year, month - 1, day);
  const dayOfWeek = daysOfWeek[inputDateObj.getDay()];
  const monthName = months[month - 1];

  const dayFormatted = day.toString().padStart(2, "0");

  // Format the date as "DayOfWeek, DD MonthName YYYY"
  return `${monthName} ${dayFormatted}, ${year}`;
}

export const schemeOptions = [
  { label: "PMKVY", value: "65aa7bf1b19149328d4ec965" },
  { label: "Non PMKVY", value: "65aa7ca8b19149328d4ecc61" },
  { label: "PM Vishvakarma", value: process.env.REACT_APP_PM_VISHWAKARMA },
];

export const getSchemeType = (data = []) => {
  if (data.length > 0) {
    const selectedLabels = data?.map((id) => {
      const option = schemeOptions?.find((option) => option.value === id);
      return option ? option.label : null;
    });
    return selectedLabels;
  }
};

export const getWidthAndHeightOfComponentDashboard = (dashboardType = "") => {
  // if(dashboardConstants===)
  let w = 0;
  let h = 0;
  switch (dashboardType) {
    case dashboardConstants?.TimeSpentBD:
      w = 1;
      h = 3.5;
      break;
    case dashboardConstants?.ClientOverviewBD:
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.ClientByLocationBD:
      w = 1;
      h = 4.5;
      break;
    case dashboardConstants?.SectorWiseOverviewBD:
      w = 1;
      h = 3;
      break;
    // case dashboardConstants?.LeadAnalyticsBD:  //need to change
    //   w = 1;
    //   h = 4;
    //   break;
    // case dashboardConstants?.LeadByCategoryBD: //need to change
    //   w = 1;
    //   h = 4;
    //   break;
    case dashboardConstants?.ScheduleCalenderBD:
      w = 1;
      h = 3;
      break;

    case dashboardConstants?.TimeSpentCD: //check which one coming
      w = 1;
      h = 3.5;
      break;
    case dashboardConstants?.DailyWorkProgressCD: //need to change height withing it
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.QuestionAnalyticsCD:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.LanguageDistributionCD:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.UpcomingBatchCD: //to change 
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.AssessorByLocationOD:
      w = 1;
      h = 4;
      break;
    case dashboardConstants?.AssessmentAnalyticsOD: //to change width and height
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.UpcomingBatchCalenderOD:
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.ClientWiseAssessmentOD:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.SchemeAnalysisOD:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.AssessmentHistoryOD:
      w = 1;
      h = 2.5;
      break;
    // case dashboardConstants?.TimeSpentMIS:
    //   w = 0.5;
    //   h = 3.5;
    //   break;
    case dashboardConstants?.ResultAnalysisMIS: //to change height of inner height  
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.ApplicantAnalysisMIS:
      w = 1;
      h = 2.5;
      break;
    // case dashboardConstants?.BatchResultStatusMIS: //to change lateron
    //   w = 1;
    //   h = 4;
    //   break;
    // case dashboardConstants?.ClientWiseAssessment:
    //   w = 1;
    //   h = 2.5;
    //   break;
    case dashboardConstants?.SchemeAnalysisMIS:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.UpcomingBatchMIS:
      w = 1;
      h = 3;
      break;
    // case dashboardConstants?.TimeSpentQA:
    //   w = 1;
    //   h = 4;
    //   break;
    // case dashboardConstants?.AssessmentAnalysisQA:
    //   w = 1;
    //   h = 2.5;
    //   break;
    case dashboardConstants?.BatchVerificationStatsQA:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.ActivityQA:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.BatchStaticsQA:
      w = 1;
      h = 2.5;
      break;

    // case dashboardConstants?.TimeSpentHR:
    //   w = 0.5;
    //   h = 2.5;
    //   break;
    case dashboardConstants?.EmploymentTypeHR:
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.AssessmentAnalysisHR: //need to change inner height
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.AssessorByLocationHR:
      w = 1;
      h = 4;
      break;

    case dashboardConstants?.TeamMemberBusiness:
      w = 1;
      h = 3.5;
      break;
    case dashboardConstants?.ClientListBusiness:
      w = 2;
      h = 3.6;
      break;

    case dashboardConstants?.JobroleOccuranceContent:
      w = 1;
      h = 3.5;
      break;
    // case dashboardConstants?.TeamMemberContent: // to change lateron
    //   w = 1;
    //   h = 4;
    //   break;
    case dashboardConstants?.ClientBasedJobroleContent:
      w = 1;
      h = 3.8;
      break;
    // case dashboardConstants?.AllActivitiesContent: //to change lateron
    //   w = 2;
    //   h = 4;
    //   break;

    case dashboardConstants?.LiveBatchStatsOperation:
      w = 1;
      h = 3;
      break;
    case dashboardConstants?.ClientBasedAssessorOperation:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.AssignedAssessorOperation:
      w = 1;
      h = 3.6;
      break;
    case dashboardConstants?.BatchListOperation:
      w = 2;
      h = 3;
      break;

    case dashboardConstants?.ClientWiseBatchMIS:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.NOSResultMIS:
      w = 2;
      h = 3;
      break;
    case dashboardConstants?.TeamMemberQA:
      w = 1;
      h = 3.2;
      break;

    case dashboardConstants?.TeamMemberOperation:
      w = 1;
      h = 3.2;
      break;
    case dashboardConstants?.RealTimeMoniteringAndQALISTQA:
      w = 2;
      h = 3;
      break;
    case dashboardConstants?.ClientBasedAssessorQA:
      w = 1;
      h = 2.5;
      break;
    case dashboardConstants?.AssessorListHR:
      w = 2;
      h = 4.2;
      break;
    default:
      w = 1;
      h = 1;
      break;
  }
  return { w: w, h: h };
};

export const findKeyByValue = (object, value) => {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null;
};

export const generateRandomColors = (numColors) => {
  const colors = [];
  const usedColors = new Set();

  for (let i = 0; i < numColors; i++) {
    let color;
    do {
      color =
        "#" +
        Math.floor(Math.random() * 0xffffff)
          .toString(16)
          .padStart(6, "0");
    } while (usedColors.has(color));

    colors.push(color);
    usedColors.add(color);
  }

  return colors;
};

export function processWidgets(value) {
  let widgets_arr = [];

  value.map((item) => {
    if (Array.isArray(item?.options)) {
      widgets_arr = widgets_arr.concat(item?.options);
      return true;
    }
    return false;
  });
  return widgets_arr;
}


export function generateRandomColorsArrayBased(arrayLength = 0) {
  const colors = [];
  const usedColors = new Set();

  for (let i = 0; i < arrayLength; i++) {
    let color;
    do {
      color = '#' + Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');
    } while (usedColors.has(color));

    colors.push(color);
    usedColors.add(color);
  }

  return colors;
}


export function getBrowser() {
  const userAgent = navigator.userAgent;

  if (window.navigator?.userAgentData?.brands?.map((item) => item.brand).includes("Brave")) {
    return "Brave";
  }

  if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("Brave")) {
    return "Chrome";
  }

  if (userAgent.includes("Firefox")) {
    return "Firefox";
  }

  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  }

  if (userAgent.includes("Edg")) {
    return "Edge";
  }

  if (userAgent.includes("Trident")) {
    return "Internet Explorer";
  }

  if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "Opera";
  }

  return "Unknown";
}
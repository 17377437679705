//dashboard
export const GET_LIST_OF_ALL_ITEMS_API = "api/listofallclients";
export const GET_CLIENT_STATISTICS_API = "api/clientsbyorgtype";
export const GET_CLIENT_SUMMARY_API = "api/clientsbymonthwise";
export const GET_WIDGET_STATS_API = "api/bdawidgetstats";

// MIS Dashboard End Points
export const GET_WIDGET_STATS_MIS_API = "api/misWidgetStats";
export const GET_TOTAL_BATCH_OVERVIEW_API = "api/batchByMonth";
export const GET_BATCH_STATISTICS_API = "api/resultPercentageStats";

// Content Dashboard
export const GET_CONTENT_DASHBOARD_API = "api/get-content-dashboard";
export const GET_OPERATION_DASHBOARD_API = "api/get-operation-details";
export const GET_OPERATION_DASHBOARD_BATCH_OVERVIEW_API ="api/batchList-Operation-Dashboard";
export const GET_HR_DASHBOARD_API = "api/get-hr-dashboard";
export const GET_WIDGET_STATS_CD="api/content-dashboard-top-card";
export const GET_QUESTION_BANK_ANALYTICS_CD="api/question-analytics";
export const GET_LANGUAGE_DISTRIBUTION_ANALYTICS_CD="api/language-analytics";
export const GET_ALL_JOBROLE_OCCURANCE="api/jobrole-occurrence";
export const GET_ALL_TEAM_MEMBER_LIST_CD="api/team-members";
export const GET_ALL_CLIENT_WITH_JOBROLE="api/client-with-job-role"





export const GET_ASSESSEDBATCH_ADMINDASHBOARD ="api/assessedBatch-adminDashboard";
export const GET_LIVE_BATCH_ADMINDASHBOARD= "api/liveBatch-adminDashboard";
export const GET_JOBROLE_ADMINDASHBOARD = "api/jobrole-adminDashboard"
export const GET_ASSESSOR_ONBOARD_ADMINDASHBOARD="api/assessorOnboard-adminDashboard";
export const GET_SECTOR_WISE_ASSESSMENT="api/get-sectorAssessment";
export const GET_ASSESSOR_BY_LOCATION="api/get-assessor-dashboard";
export const GET_ASSESSMENT_ANALYTICS="api/get-assessmentAnalytics-details";
export const GET_CLIENT_BASED_JOBROLE="api/clientJobrole-adminDashboard";
export const GET_ASSESSED_APPLICANTS="api/resultPercentageStats-ncvtDashboard"
// export const GET_LIVE_BATCH_LIST = "api/batch-list";
export const GET_LIVE_BATCH_LIST = "api/get-liveBatch-logList";

export const GET_SCHEDULED_BATCH_LIST= "api/get-schedule-batchList";
export const GET_SCHEDULED_BATCH_LIST_CD= "api/get-scheduledBatch-details";

export const GET_TOTAL_JOBROLE_COMMONDASHBOARD = "api/get-content-dashboard-totalJobrole"



